import Loadable from 'components/Loadable';
import {
  ENABLE_CB_COUNTRY,
  ENABLE_CB_CREATE,
  ENABLE_CB_OFFICE,
  ENABLE_CB_READ,
  ENABLE_CB_SECTORS,
  ENABLE_CB_STANDARDS,
} from 'configs/enable-features';

const cbV1Routes = [
  {
    exact: true,
    path: '/certification-bodies/v1',
    component: Loadable({
      loader: () => import('../views/certification-bodies/v1/list'),
    }),
  },
  {
    exact: true,
    path: '/certification-bodies/v1/create',
    component:
      ENABLE_CB_CREATE &&
      Loadable({
        loader: () => import('../views/certification-bodies/v1/create'),
      }),
  },
  {
    path: '/certification-bodies/v1/:cbId',
    component: Loadable({
      loader: () => import('../views/certification-bodies/v1/view'),
    }),
    routes: [
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId',
        component:
          ENABLE_CB_READ &&
          Loadable({
            loader: () =>
              import('../views/certification-bodies/v1/view/details'),
          }),
      },
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/standards',
        component:
          ENABLE_CB_STANDARDS &&
          Loadable({
            loader: () =>
              import('../views/certification-bodies/v1/view/standards'),
          }),
      },
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/sectors',
        component:
          ENABLE_CB_SECTORS &&
          Loadable({
            loader: () =>
              import('../views/certification-bodies/v1/view/sectors'),
          }),
      },
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/countries',
        component:
          ENABLE_CB_COUNTRY &&
          Loadable({
            loader: () =>
              import('../views/certification-bodies/v1/view/countries'),
          }),
      },
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/office-directories',
        component:
          ENABLE_CB_OFFICE &&
          Loadable({
            loader: () =>
              import(
                '../views/certification-bodies/v1/view/office-directories'
              ),
          }),
      },
      // Certified entity routes
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/certified-entities',
        component: Loadable({
          loader: () =>
            import(
              '../views/certification-bodies/v1/view//certified-entities/list'
            ),
        }),
      },
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/certified-entities/create',
        component: Loadable({
          loader: () =>
            import(
              '../views/certification-bodies/v1/view/certified-entities/create'
            ),
        }),
      },
      {
        path: '/certification-bodies/v1/:cbId/certified-entities/:ceId',
        component: Loadable({
          loader: () =>
            import(
              '../views/certification-bodies/v1/view/certified-entities/view'
            ),
        }),
        routes: [
          {
            exact: true,
            path: '/certification-bodies/v1/:cbId/certified-entities/:ceId',
            component: Loadable({
              loader: () =>
                import(
                  '../views/certification-bodies/v1/view/certified-entities/view/details'
                ),
            }),
          },

          {
            exact: true,
            path: '/certification-bodies/v1/:cbId/certified-entities/:ceId/certifications',
            component: Loadable({
              loader: () =>
                import(
                  '../views/certification-bodies/v1/view/certified-entities/view/certifications/list'
                ),
            }),
          },

          {
            exact: true,
            path: '/certification-bodies/v1/:cbId/certified-entities/:ceId/certifications/create',
            component: Loadable({
              loader: () =>
                import(
                  '../views/certification-bodies/v1/view/certified-entities/view/certifications/create'
                ),
            }),
          },
          {
            exact: true,
            path: '/certification-bodies/v1/:cbId/certified-entities/:ceId/certifications/:certId',
            component: Loadable({
              loader: () =>
                import(
                  '../views/certification-bodies/v1/view/certified-entities/view/certifications/view'
                ),
            }),
          },
        ],
      },
      {
        exact: true,
        path: '/certification-bodies/v1/:cbId/certificate-standards',
        component: Loadable({
          loader: () =>
            import(
              '../views/certification-bodies/v1/view/certificate-standards'
            ),
        }),
      },
    ],
  },
];

export default cbV1Routes;

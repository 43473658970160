import React from 'react';
import {Redirect} from 'react-router';
import Loadable from 'components/Loadable';

import {ENABLE_CB} from 'configs/enable-features';
import {CB_TEMPLATE_VERSIONS_URL} from 'configs';
import cbV1Routes from './certification-bodies-v1';
import cbv2Routes from './certification-bodies-v2';

const certificationBodies = {
  path: '/certification-bodies',
  component:
    ENABLE_CB &&
    Loadable({
      loader: () => import('../views/certification-bodies'),
    }),
  routes: [
    // handling for existing urls that has following format /certification-bodies
    {
      exact: true,
      path: '/certification-bodies',
      component: (props) => {
        const {cbTemplate} = props;

        if (!cbTemplate) return <Redirect to={`/error/404`} />;

        return (
          <Redirect
            to={`/certification-bodies/${CB_TEMPLATE_VERSIONS_URL[cbTemplate]}`}
          />
        );
      },
    },
    {
      path: '/certification-bodies/v1',
      component:
        ENABLE_CB &&
        Loadable({
          loader: () => import('../views/certification-bodies/v1'),
        }),
      routes: cbV1Routes,
    },
    {
      path: '/certification-bodies/v2',
      component: Loadable({
        loader: () => import('../views/certification-bodies/v2'),
      }),
      routes: cbv2Routes,
    },
    // handling for existing urls that has following format /certification-bodies/:cbId/**
    {
      exact: false,
      path: '/certification-bodies/:cbId',
      component: (props) => {
        const {location, cbTemplate} = props;
        const {pathname} = location;

        const regex = /^v\d$/; // v1, v2, v3

        const matches = pathname
          .split('/')
          .filter((v) => !!v && !regex.test(v));

        const firstSegment = matches.shift();
        const lastSegment = matches.join('/');

        return (
          <Redirect
            to={`/${firstSegment}/${CB_TEMPLATE_VERSIONS_URL[cbTemplate]}/${lastSegment}`}
          />
        );
      },
    },
  ],
};

export default certificationBodies;

import Loadable from 'components/Loadable';

const ceLiveRoutes = [
  {
    exact: true,
    path: '/certified-entities/live',
    component: Loadable({
      loader: () => import('../views/certified-entities/live/list'),
    }),
  },
  {
    path: '/certified-entities/live/:ceId',
    component: Loadable({
      loader: () => import('../views/certified-entities/live/view'),
    }),
    routes: [
      {
        exact: true,
        path: '/certified-entities/live/:ceId',
        component: Loadable({
          loader: () => import('../views/certified-entities/live/view/details'),
        }),
      },
    ],
  },
];

export default ceLiveRoutes;

import React from 'react';
import {Redirect} from 'react-router-dom';
import Loadable from 'components/Loadable';

const importManagement = {
  path: '/import-management',
  component: Loadable({
    loader: () => import('../views/import-management'),
  }),
  routes: [
    {
      exact: true,
      path: '/import-management',
      component: () => <Redirect to="/import-management/data-import" />,
    },
    {
      path: '/import-management/data-import',
      component: Loadable({
        loader: () => import('../views/import-management/data-import'),
      }),
      routes: [
        {
          exact: true,
          path: '/import-management/data-import',
          component: Loadable({
            loader: () =>
              import('../views/import-management/data-import/overview'),
          }),
        },
        {
          exact: true,
          path: '/import-management/data-import/uploader',
          component: Loadable({
            loader: () =>
              import('../views/import-management/data-import/uploader'),
          }),
        },
        {
          exact: true,
          path: '/import-management/data-import/api-integration',
          component: Loadable({
            loader: () =>
              import('../views/import-management/data-import/api-integration'),
          }),
        },
        {
          exact: true,
          path: '/import-management/data-import/ftp-server',
          component: Loadable({
            loader: () =>
              import('../views/import-management/data-import/ftp-server'),
          }),
        },
      ],
    },
    {
      path: '/import-management/ftp-settings',
      component: Loadable({
        loader: () => import('../views/import-management/ftp-settings'),
      }),
    },
    {
      path: '/import-management/activities',
      component: Loadable({
        loader: () => import('../views/import-management/activities'),
      }),
      routes: [
        {
          exact: true,
          path: '/import-management/activities',
          component: Loadable({
            loader: () =>
              import('../views/import-management/activities/overview'),
          }),
        },
        {
          exact: true,
          path: '/import-management/activities/:batchId',
          component: Loadable({
            loader: () =>
              import('../views/import-management/activities/details'),
          }),
        },
      ],
    },
  ],
};

export default importManagement;

import Loadable from 'components/Loadable';

import {
  ENABLE_CE_READ,
  ENABLE_CE_CREATE,
  ENABLE_CERT_CREATE,
} from 'configs/enable-features';

const cev1 = [
  {
    exact: true,
    path: '/certified-entities/v1',
    component: Loadable({
      loader: () => import('../views/certified-entities/v1/list'),
    }),
  },
  {
    exact: true,
    path: '/certified-entities/v1/create',
    component:
      ENABLE_CE_CREATE &&
      Loadable({
        loader: () => import('../views/certified-entities/v1/create'),
      }),
  },
  {
    path: '/certified-entities/v1/:ceId',
    component:
      ENABLE_CE_READ &&
      Loadable({
        loader: () => import('../views/certified-entities/v1/view'),
      }),
    routes: [
      {
        exact: true,
        path: '/certified-entities/v1/:ceId',
        component: Loadable({
          loader: () => import('../views/certified-entities/v1/view/details'),
        }),
      },
      {
        exact: true,
        path: '/certified-entities/v1/:ceId/certifications',
        component: Loadable({
          loader: () =>
            import('../views/certified-entities/v1/view/certifications/list'),
        }),
      },
      // {
      //   exact: true,
      //   path: '/certified-entities/v1/:ceId/sites',
      //   component: Loadable({
      //     loader: () =>
      //       import('../views/certified-entities/v1/view/sites/list'),
      //   }),
      // },
      {
        exact: true,
        path: '/certified-entities/v1/:ceId/certifications/create',
        component:
          ENABLE_CERT_CREATE &&
          Loadable({
            loader: () =>
              import(
                '../views/certified-entities/v1/view/certifications/create'
              ),
          }),
      },
    ],
  },
];

export default cev1;

import React from 'react';
import {Redirect} from 'react-router-dom';
import Loadable from 'components/Loadable';

const cbUpload = {
  path: '/cb-upload',
  component: Loadable({
    loader: () => import('../views/cb-upload'),
  }),
  routes: [
    {
      exact: true,
      path: '/cb-upload',
      component: () => <Redirect to="/cb-upload/file-upload" />,
    },
    {
      exact: true,
      path: '/cb-upload/file-upload',
      component: Loadable({
        loader: () => import('../views/cb-upload/file-upload'),
      }),
    },
    {
      exact: true,
      path: '/cb-upload/ftp-access',
      component: Loadable({
        loader: () => import('../views/cb-upload/ftp-access'),
      }),
    },
    {
      exact: true,
      path: '/cb-upload/activity',
      component: Loadable({
        loader: () => import('../views/cb-upload/activity'),
      }),
    },
  ],
};

export default cbUpload;

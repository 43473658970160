import React from 'react';
import {withRouter} from 'react-router';
import {withViewport} from './ViewportContext';

const SidebarContext = React.createContext();
const SidebarConsumer = SidebarContext.Consumer;

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Reset
    if (
      this.props.location !== prevProps.location ||
      this.props.viewport !== prevProps.viewport
    ) {
      this.setState({
        isOpen: false,
      });
    }

    if (this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {isOpen} = this.state;

    return (
      <SidebarContext.Provider
        value={{
          isOpen: isOpen,
          toggle: this.toggle,
        }}
      >
        {this.props.children}

        {isOpen && (
          <div
            style={{
              top: 0,
              left: 0,
              zIndex: 999,
              position: 'fixed',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0, 0.5)',
            }}
            onClick={() => this.setState({isOpen: false})}
          />
        )}
      </SidebarContext.Provider>
    );
  }
}

const SidebarProvider = withRouter(withViewport(Component));

const withSidebar = (Component) => {
  return class extends React.Component {
    render() {
      return (
        <SidebarConsumer>
          {(sidebar) => <Component sidebar={sidebar} {...this.props} />}
        </SidebarConsumer>
      );
    }
  };
};

export {SidebarProvider, SidebarConsumer, withSidebar};

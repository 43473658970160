import Loadable from 'components/Loadable';

const abStandards = {
  path: '/ab-standards',
  component: Loadable({
    loader: () => import('../views/ab-standards'),
  }),
  routes: [
    {
      exact: true,
      path: '/ab-standards',
      component: Loadable({
        loader: () => import('../views/ab-standards/list'),
      }),
    },
    {
      exact: true,
      path: '/ab-standards/create',
      component: Loadable({
        loader: () => import('../views/ab-standards/create'),
      }),
    },
    {
      exact: true,
      path: '/ab-standards/iaf-mla',
      component: Loadable({
        loader: () => import('../views/ab-standards/iaf-mla'),
      }),
    },
    {
      path: '/ab-standards/technical-sectors',
      component: Loadable({
        loader: () => import('../views/ab-standards/technical-sectors'),
      }),
    },
    {
      path: '/ab-standards/:standardId',
      component: Loadable({
        loader: () => import('../views/ab-standards/view'),
      }),
      routes: [
        {
          exact: true,
          path: '/ab-standards/:standardId',
          component: Loadable({
            loader: () => import('../views/ab-standards/view/details'),
          }),
        },
      ],
    },
  ],
};

export default abStandards;

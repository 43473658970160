import {UUID_VERSION} from 'configs';
import {CONFIDENTIAL_TYPE} from 'configs/types';
import isUUID from 'validator/es/lib/isUUID';
import {isString} from 'lodash';
/**
 * Checks if str has prefix
 * @param {string} str
 * @param {string} prefix
 * @returns {boolean}
 */
export const hasPrefix = (str, prefix) => {
  return !!str && isString(str) && str.startsWith(prefix);
};

/**
 * Adds prefix if it doesn't exist
 * @param {string} str
 * @param {string} prefix
 * @returns {string}
 */
export const addPrefix = (str, prefix) => {
  return hasPrefix(str, prefix) ? str : prefix.concat(str);
};

/**
 * Removes prefix if it exists
 * @param {string} str
 * @param {string} prefix
 * @return {string}
 */
export const removePrefix = (str, prefix) => {
  return hasPrefix(str, prefix) ? str.slice(prefix.length) : str;
};

/**
 * Checks if the string is UUID
 * @param {string} str
 * @returns {boolean}
 */
export const uuidValidate = (str) => {
  return isUUID(str, UUID_VERSION);
};

export const stringifyStandardId = (value) => {
  if (
    value.hasOwnProperty('standards_id') &&
    value.hasOwnProperty('scheme_id') &&
    value.hasOwnProperty('accreditation_bodies_id')
  )
    return Object.values(value)
      .map((v) => v)
      .join('__');

  return null;
};

export const stringifyCBStandardId = (value) => {
  if (value.hasOwnProperty('standards_id') && value.hasOwnProperty('scheme_id'))
    return Object.values(value)
      .map((v) => v)
      .join('__');

  return null;
};

export const parseStandardId = (value) => {
  const keys = ['standards_id', 'scheme_id', 'accreditation_bodies_id'];
  const split = value.split('__');
  if (split.length !== keys.length) return null;

  const result = {};

  split.forEach((string, index) => {
    Object.assign(result, {...result, [keys[index]]: string});
  });

  return result;
};

export const parseCBStandardId = (value) => {
  const keys = ['standards_id', 'scheme_id'];
  const split = value.split('__');
  if (split.length !== keys.length) return null;

  const result = {};

  split.forEach((string, index) => {
    Object.assign(result, {...result, [keys[index]]: string});
  });

  return result;
};

export const formatStandardSchemeName = (standardName, schemeName) => {
  const delimiter = ' - ';

  return [standardName, schemeName].filter((v) => v).join(delimiter);
};

export const joinWithAnd = (arr, separator = 'and') => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(` ${separator} `);
  return `${arr.slice(0, -1).join(', ')}, ${separator} ${arr[arr.length - 1]}`;
};

export const splitString = (str = '', separator = ' | ') => {
  if (!str) return '';

  const parts = str.split(separator);

  if (parts.length === 1) {
    return parts[0]; // Only one part, return it as is
  } else {
    const firstPart = parts.shift(); // Remove and get the first part
    const secondPart = parts.join(separator); // Join remaining parts with separator
    return [firstPart, secondPart].filter((v) => v);
  }
};

export function checkConfidentialType(array = []) {
  if (array.some((el) => el === CONFIDENTIAL_TYPE['all'])) {
    return CONFIDENTIAL_TYPE['all'];
  } else if (array.length === 0) {
    return CONFIDENTIAL_TYPE['none'];
  } else {
    return CONFIDENTIAL_TYPE['specified'];
  }
}

export function checkSpecified(field, array) {
  return array.includes(field);
}

import numeral from 'numeral';

export function getFormattedNumber(value, formatter = '0,0') {
  // 3500 will be displayed "3,500" if in U.S. English locale
  return numeral(value).format(formatter);
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1000 ** i).toFixed(1)} ${sizes[i]}`;
};

import Loadable from 'components/Loadable';

import {ENABLE_AB_STANDARDS, ENABLE_AB_SECTORS} from 'configs/enable-features';

const standards = {
  path: '/standards',
  component: Loadable({
    loader: () => import('../views/standards'),
  }),
  routes: [
    {
      exact: true,
      path: '/standards',
      component:
        ENABLE_AB_STANDARDS &&
        Loadable({
          loader: () => import('../views/standards/list'),
        }),
    },
    {
      exact: true,
      path: '/standards/iaf-standards',
      component: Loadable({
        loader: () => import('../views/standards/iaf-standards'),
      }),
    },
    {
      exact: true,
      path: '/standards/sectors',
      component:
        ENABLE_AB_SECTORS &&
        Loadable({
          loader: () => import('../views/standards/sectors'),
        }),
    },
    {
      exact: true,
      path: '/standards/countries',
      component: Loadable({
        loader: () => import('../views/standards/countries'),
      }),
    },
    {
      exact: true,
      path: '/standards/office-directories',
      component: Loadable({
        loader: () => import('../views/standards/office-directories'),
      }),
    },
  ],
};

export default standards;

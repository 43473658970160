import {isNumber, isEmpty, isString, isObject} from 'lodash/lang';
import LocalStorage from './localStorage';

const countries = LocalStorage.countries.data;

export function getCountryCodeById(countryId) {
  const countries = LocalStorage.countries.data;

  if (!isNumber(countryId) && isEmpty(countryId)) countryId = '1'; // Set US as default.
  if (!isString(countryId)) countryId = countryId.toString();

  const country = (!!countries && countries[countryId]) || {};

  const {code} = country || {};
  return code;
}

export function getCountryNameById(countryId) {
  if (!isNumber(countryId) && isEmpty(countryId)) countryId = '1'; // Set US as default.
  if (isString(countryId)) countryId = countryId.toString();

  const country = countries[countryId] || {};
  return country.name;
}

export function getCountryCodeByName(countryName) {
  if (isNumber(countryName)) countryName = 'United States'; // set US as default.

  const countriesArray = Object.values(countries);

  return (countriesArray.find((country) => country.name === countryName) || {})
    .code;
}

export function getCountryIdByName(countryName) {
  if (isNumber(countryName)) countryName = 'United States'; // set US as default.

  const countriesArray = Object.values(countries);
  return (countriesArray.find((country) => country.name === countryName) || {})
    .countries_id;
}

export function getAddressByComponent(component = {}) {
  if (isEmpty(component) || !isObject(component)) return '';

  const {address, street, city, suburb, state, country, postcode} = component;

  let arr = [];

  if (address) {
    arr.push(address);
  } else {
    if (street) arr.push(street);
    if (city) arr.push(city);
    if (suburb) arr.push(suburb);
    if (state) arr.push(state);
    if (country) arr.push(country);
    if (postcode) arr.push(postcode);
  }

  return arr.join(', ');
}

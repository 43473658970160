import React from 'react';
import {Redirect} from 'react-router';
import Loadable from 'components/Loadable';
import {ENABLE_CE} from 'configs/enable-features';
import {CERT_TEMPLATE_VERSIONS_URL} from 'configs';
import certV1Routes from './certifications-v1';
import certV2Routes from './certifications-v2';
import certLiveRoutes from './certifications-live';

const certifications = {
  path: '/certifications',
  component:
    ENABLE_CE &&
    Loadable({
      loader: () => import('../views/certifications'),
    }),
  routes: [
    {
      exact: true,
      path: '/certifications',
      component: (props) => {
        const {certTemplate} = props;

        if (!certTemplate) return <Redirect to={`/error/404`} />;

        return (
          <Redirect
            to={`/certifications/${CERT_TEMPLATE_VERSIONS_URL[certTemplate]}`}
          />
        );
      },
    },
    {
      path: '/certifications/live',
      component: Loadable({
        loader: () => import('../views/certifications/live'),
      }),
      routes: certLiveRoutes,
    },
    {
      path: '/certifications/v1',
      component:
        ENABLE_CE &&
        Loadable({
          loader: () => import('../views/certifications/v1'),
        }),
      routes: certV1Routes,
    },
    {
      path: '/certifications/v2',
      component:
        ENABLE_CE &&
        Loadable({
          loader: () => import('../views/certifications/v2'),
        }),
      routes: certV2Routes,
    },
    {
      exact: false,
      path: '/certifications/:certId',
      component: (props) => {
        const {location, certTemplate} = props;
        const {pathname} = location;
        const regex = /^v\d$/; // v1, v2, v3

        const matches = pathname
          .split('/')
          .filter((v) => !!v && !regex.test(v));

        const firstSegment = matches.shift();
        const lastSegment = matches.join('/');

        // overrides versioning on live routes
        const liveRegex = /live/;

        if (liveRegex.test(pathname)) {
          return <Redirect to={pathname} />;
        }

        return (
          <Redirect
            to={`/${firstSegment}/${CERT_TEMPLATE_VERSIONS_URL[certTemplate]}/${lastSegment}`}
          />
        );
      },
    },
  ],
};

export default certifications;

import React from 'react';
import {Redirect} from 'react-router';
import Loadable from 'components/Loadable';
import {ENABLE_CE} from 'configs/enable-features';
import {CERT_TEMPLATE_VERSIONS_URL} from 'configs';

import ceV2Routes from './certified-entities-v2';
import ceV1Routes from './certified-entities-v1';
import ceLiveRoutes from './certified-entities-live';

const certifiedEntities = {
  path: '/certified-entities',
  component:
    ENABLE_CE &&
    Loadable({
      loader: () => import('../views/certified-entities'),
    }),
  routes: [
    {
      exact: true,
      path: '/certified-entities',
      component: (props) => {
        const {certTemplate} = props;

        if (!certTemplate) return <Redirect to={`/error/404`} />;

        return (
          <Redirect
            to={`/certified-entities/${CERT_TEMPLATE_VERSIONS_URL[certTemplate]}`}
          />
        );
      },
    },
    {
      path: '/certified-entities/live',
      component: Loadable({
        loader: () => import('../views/certified-entities/live'),
      }),
      routes: ceLiveRoutes,
    },
    {
      path: '/certified-entities/v1',
      component:
        ENABLE_CE &&
        Loadable({
          loader: () => import('../views/certified-entities/v1'),
        }),
      routes: ceV1Routes,
    },
    {
      path: '/certified-entities/v2',
      component:
        ENABLE_CE &&
        Loadable({
          loader: () => import('../views/certified-entities/v2'),
        }),
      routes: ceV2Routes,
    },
    {
      exact: false,
      path: '/certified-entities/:ceId',
      component: (props) => {
        const {location, certTemplate} = props;
        const {pathname} = location;

        const regex = /^v\d$/; // v1, v2, v3

        const matches = pathname
          .split('/')
          .filter((v) => !!v && !regex.test(v));

        const firstSegment = matches.shift();
        const lastSegment = matches.join('/');

        // overrides versioning on live routes
        const liveRegex = /live/;

        if (liveRegex.test(pathname)) {
          return <Redirect to={pathname} />;
        }

        return (
          <Redirect
            to={`/${firstSegment}/${CERT_TEMPLATE_VERSIONS_URL[certTemplate]}/${lastSegment}`}
          />
        );
      },
    },
  ],
};

export default certifiedEntities;

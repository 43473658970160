import Loadable from 'components/Loadable';
import {ENABLE_BRANCHES, ENABLE_BRANCHES_CREATE} from 'configs/enable-features';

const branches = {
  path: '/branches',
  component:
    ENABLE_BRANCHES &&
    Loadable({
      loader: () => import('../views/branches'),
    }),
  routes: [
    {
      exact: true,
      path: '/branches',
      component: Loadable({
        loader: () => import('../views/branches/list'),
      }),
    },
    {
      exact: true,
      path: '/branches/create',
      component:
        ENABLE_BRANCHES_CREATE &&
        Loadable({
          loader: () => import('../views/branches/create'),
        }),
    },
    {
      path: '/branches/:branchId',
      component: Loadable({
        loader: () => import('../views/branches/view'),
      }),
      routes: [
        {
          exact: true,
          path: '/branches/:branchId',
          component: Loadable({
            loader: () => import('../views/branches/view/details'),
          }),
        },
        {
          exact: true,
          path: '/branches/:branchId/members/:userId?/:type?',
          component: Loadable({
            loader: () => import('../views/branches/view/members'),
          }),
        },
      ],
    },
  ].filter((v) => !!v),
};

export default branches;

import Loadable from 'components/Loadable';

const certLiveRoutes = [
  {
    exact: true,
    path: '/certifications/live',
    component: Loadable({
      loader: () => import('../views/certifications/live/list'),
    }),
  },
  {
    path: '/certifications/live/:certId',
    component: Loadable({
      loader: () => import('../views/certifications/live/view'),
    }),
    routes: [
      {
        exact: true,
        path: '/certifications/live/:certId',
        component: Loadable({
          loader: () => import('../views/certifications/live/view/details'),
        }),
      },
      {
        exact: true,
        path: '/certifications/live/:certId/sites',
        component: Loadable({
          loader: () => import('../views/certifications/live/view/sites'),
        }),
      },
      {
        exact: true,
        path: '/certifications/live/:certId/sectors',
        component: Loadable({
          loader: () => import('../views/certifications/live/view/sectors'),
        }),
      },
    ],
  },
];

export default certLiveRoutes;

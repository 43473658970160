import React from 'react';
import {Redirect} from 'react-router';
import Loadable from 'components/Loadable';

const cbProfile = {
  path: '/cb-profile',
  component: Loadable({
    loader: () => import('../views/cb-profile'),
  }),
  routes: [
    {
      exact: true,
      path: '/cb-profile',
      component: () => <Redirect to="/cb-profile/certification-body" />,
    },
    {
      exact: true,
      path: '/cb-profile/certification-body',
      component: Loadable({
        loader: () => import('../views/cb-profile/certification-body'),
      }),
    },
    {
      exact: true,
      path: '/cb-profile/accreditation',
      component: Loadable({
        loader: () => import('../views/cb-profile/accreditation'),
      }),
    },
    // {
    //   exact: true,
    //   path: '/cb-profile/signed-agreements',
    //   component: Loadable({
    //     loader: () => import('../views/cb-profile/signed-agreements'),
    //   }),
    // },
  ].filter((v) => v),
};

export default cbProfile;

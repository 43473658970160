import React, {useState, useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody, Button, Row, Col} from 'reactstrap';
import {useAuth} from 'contexts/AuthContext';
import {
  ACCOUNT_ROLES,
  CB_TEMPLATE_VERSIONS,
  CERT_TEMPLATE_VERSIONS,
  COMPANY_TYPES,
} from 'configs';
import routes from 'routes';
import {isEmpty, omitBy, isNil} from 'lodash';
import LocalStorage, {removeItem} from 'utils/localStorage';
import RELEASE_NOTES from 'configs/release-notes';
import deepEqual from 'deep-equal';
import {IconFA} from 'components/Icons';
import styles from './styles.module.scss';

function recurseRoutes(values) {
  const {routes = []} = values || {};
  let result = [];

  routes.forEach((r) => {
    const {path, routes} = r;

    result.push(path);
    if (!isEmpty(routes)) {
      result = [...result, ...recurseRoutes({routes: routes})];
    }
  });

  return result;
}

function getPrivateRoutes() {
  let privates = {routes: routes};
  const recurse = [...new Set(recurseRoutes(privates))];
  return recurse.filter(
    (r) =>
      r !== '/' &&
      r !== '/consents' &&
      r !== '/setup' &&
      r !== '/logout' &&
      r !== '/e-signature' &&
      !!r
  );
}

function TemplateVersioningNotifyUpdateModal() {
  const {isAuth, account, token, cbTemplate, certTemplate} = useAuth();
  const {account_role, company, account_id} = account || {};
  const {company_type, is_default} = company || {};
  const isMatch = useRouteMatch(getPrivateRoutes());
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const storage = LocalStorage.dontShowUpdateVersionNotif;
    const newStorage = omitBy(
      {
        account_id,
        token,
      },
      isNil
    );

    if (!isEmpty(account) && !deepEqual(storage, newStorage) && !!isMatch) {
      const templateValidator = isMNCB
        ? certTemplate === CERT_TEMPLATE_VERSIONS[2]
        : (certTemplate === CERT_TEMPLATE_VERSIONS[2] ||
            cbTemplate === CB_TEMPLATE_VERSIONS[2]) &&
          is_default === 1;

      const validator =
        isAuth &&
        company_type !== COMPANY_TYPES.ce &&
        templateValidator &&
        !isSuperAdmin &&
        !!isMatch;

      if (validator) {
        LocalStorage.dontShowUpdateVersionNotif = newStorage;
        setIsOpen(validator);
      }
    }
  }, [
    account_id,
    token,
    account,
    isMatch,
    isAuth,
    company_type,
    is_default,
    cbTemplate,
    // isMounted.current,
  ]);

  useEffect(() => {
    if (!isAuth) {
      removeItem('dontShowUpdateVersionNotif');
    }
  }, [isAuth]);

  const isNewVersion =
    cbTemplate === CB_TEMPLATE_VERSIONS[2] ||
    certTemplate === CERT_TEMPLATE_VERSIONS[2];
  const isSuperAdmin = account_role === ACCOUNT_ROLES.super_admin;
  const isCE = company_type === COMPANY_TYPES.ce;
  const isMNCB = company_type === COMPANY_TYPES.mncb;

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  if (
    !isAuth ||
    isCE ||
    is_default === 0 ||
    isSuperAdmin ||
    !isNewVersion ||
    !isMatch
  ) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader
        toggle={toggleOpen}
        className="border-bottom-0"
      ></ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-center flex-column mb-2">
          <h3 className="font-weight-bold text-primary text-center mb-4">
            Template has been updated to version 2.0.
          </h3>
          <Row className="justify-content-center align-items-stretch">
            {!!cbTemplate &&
              !isMNCB &&
              cbTemplate === CB_TEMPLATE_VERSIONS[2] && (
                <Col>
                  <div className={styles['template-version-card']}>
                    <IconFA
                      name="users"
                      className="text-primary fa-2x mb-3 mt-2"
                    />
                    <p className="text-primary font-weight-bold mb-0">
                      Certification Body
                    </p>
                    <p className="mb-0 font-weight-bold">
                      <span className="text-muted">v1 → </span>
                      <span className="text-primary">v2</span>
                    </p>
                  </div>
                </Col>
              )}
            {!!certTemplate && certTemplate === CERT_TEMPLATE_VERSIONS[2] && (
              <Col>
                <div className={styles['template-version-card']}>
                  <IconFA
                    name="file"
                    className="text-primary fa-2x mb-3 mt-2"
                  />
                  <p className="text-primary font-weight-bold mb-0">
                    Certifications
                  </p>
                  <p className="mb-0 font-weight-bold">
                    <span className="text-muted">v1 → </span>
                    <span className="text-primary">v2</span>
                  </p>
                </div>
              </Col>
            )}
          </Row>

          <span className="text-center mt-4 mb-5">
            <p>
              The template has been updated to the latest version by a super
              admin. The system is now ready to process your data using Template
              Version 2.0.
            </p>
            <p className="mb-0">
              For more details on the latest enhancements and updates please
              refer to the release notes
            </p>
          </span>

          <Button
            tag="a"
            href={
              certTemplate === CERT_TEMPLATE_VERSIONS[2]
                ? RELEASE_NOTES['july22']
                : RELEASE_NOTES['march']
            }
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            View Release Notes
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default TemplateVersioningNotifyUpdateModal;

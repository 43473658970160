import Loadable from 'components/Loadable';

import {ENABLE_CERT_READ, ENABLE_CERT_SITES} from 'configs/enable-features';

const certv2 = [
  {
    exact: true,
    path: '/certifications/v2',
    component: Loadable({
      loader: () => import('../views/certifications/v2/list'),
    }),
  },
  {
    exact: true,
    path: '/certifications/v2/create',
    component: Loadable({
      loader: () => import('../views/certifications/v2/create'),
    }),
  },
  {
    path: '/certifications/v2/:certId',
    component:
      ENABLE_CERT_READ &&
      Loadable({
        loader: () => import('../views/certifications/v2/view'),
      }),
    routes: [
      {
        exact: true,
        path: '/certifications/v2/:certId',
        component: Loadable({
          loader: () => import('../views/certifications/v2/view/details'),
        }),
      },
      {
        exact: true,
        path: '/certifications/v2/:certId/sites',
        component:
          ENABLE_CERT_SITES &&
          Loadable({
            loader: () => import('../views/certifications/v2/view/sites'),
          }),
      },
      {
        exact: true,
        path: '/certifications/v2/:certId/technical-sectors',
        component: Loadable({
          loader: () =>
            import('../views/certifications/v2/view/technical-sectors'),
        }),
      },
    ],
  },
];

export default certv2;

import Loadable from 'components/Loadable';

const reports = {
  path: '/reports',
  component: Loadable({
    loader: () => import('../views/reports'),
  }),
  routes: [
    {
      exact: true,
      path: '/reports',
      component: Loadable({
        loader: () => import('../views/reports/list'),
      }),
    },
    {
      exact: true,
      path: '/reports/create',
      component: Loadable({
        loader: () => import('../views/reports/create'),
      }),
    },
    {
      exact: true,
      path: '/reports/:reportId',
      component: Loadable({
        loader: () => import('../views/reports/view'),
      }),
    },
  ],
};

export default reports;

import React from 'react';
import Loadable from 'components/Loadable';
import {Redirect} from 'react-router-dom';

const importSettings = {
  path: '/import-settings',
  component: Loadable({
    loader: () => import('../views/import-settings'),
  }),
  routes: [
    {
      exact: true,
      path: '/import-settings',
      component: () => <Redirect to="/import-settings/ftp-settings" />,
    },
    {
      path: '/import-settings/ftp-settings',
      component: Loadable({
        loader: () => import('../views/import-settings/ftp-settings'),
      }),
    },
    {
      path: '/import-settings/api-integration',
      component: Loadable({
        loader: () => import('../views/import-settings/api-integration'),
      }),
    },
    {
      path: '/import-settings/global-mapping',
      component: Loadable({
        loader: () => import('../views/import-settings/global-mapping'),
      }),
      routes: [
        {
          exact: true,
          path: '/import-settings/global-mapping',
          component: () => (
            <Redirect to="/import-settings/global-mapping/data-mapping" />
          ),
        },
        {
          exact: true,
          path: '/import-settings/global-mapping/data-mapping',
          component: Loadable({
            loader: () =>
              import('../views/import-settings/global-mapping/data-mapping'),
          }),
        },
        {
          exact: true,
          path: '/import-settings/global-mapping/field-mapping',
          component: Loadable({
            loader: () =>
              import('../views/import-settings/global-mapping/field-mapping'),
          }),
        },
      ],
    },
    {
      path: '/import-settings/template-versioning',
      component: Loadable({
        loader: () => import('../views/import-settings/template-versioning'),
      }),
      routes: [
        {
          exact: true,
          path: '/import-settings/template-versioning',
          component: () => (
            <Redirect to="/import-settings/template-versioning/certification-body" />
          ),
        },
        {
          exact: true,
          path: '/import-settings/template-versioning/certification',
          component: Loadable({
            loader: () =>
              import(
                '../views/import-settings/template-versioning/certification'
              ),
          }),
        },
        {
          exact: true,
          path: '/import-settings/template-versioning/certification-body',
          component: Loadable({
            loader: () =>
              import(
                '../views/import-settings/template-versioning/certification-body'
              ),
          }),
        },
      ],
    },
    {
      path: '/import-settings/auto-import-settings',
      component: Loadable({
        loader: () => import('../views/import-settings/auto-import-settings'),
      }),
    },
  ],
};

export default importSettings;

import React from 'react';
import {Redirect} from 'react-router-dom';
import Loadable from 'components/Loadable';

const analytics = {
  path: '/analytics',
  component: Loadable({
    loader: () => import('../views/analytics'),
  }),
  routes: [
    {
      exact: true,
      path: '/analytics',
      component: () => <Redirect to="/analytics/certifications" />,
    },
    {
      path: '/analytics/certifications',
      component: Loadable({
        loader: () => import('../views/analytics/certifications'),
      }),
    },
    {
      path: '/analytics/sites',
      component: Loadable({
        loader: () => import('../views/analytics/sites'),
      }),
    },
    {
      path: '/analytics/certified-entities',
      component: Loadable({
        loader: () => import('../views/analytics/certified-entities'),
      }),
    },
    {
      path: '/analytics/certification-bodies',
      component: Loadable({
        loader: () => import('../views/analytics/certification-bodies'),
      }),
    },
    {
      path: '/analytics/locations',
      component: Loadable({
        loader: () => import('../views/analytics/locations'),
      }),
    },
  ],
};

export default analytics;

import Loadable from 'components/Loadable';

const cbStandards = {
  path: '/cb-standards',
  component: Loadable({
    loader: () => import('../views/cb-standards'),
  }),
  routes: [
    {
      exact: true,
      path: '/cb-standards',
      component: Loadable({
        loader: () => import('../views/cb-standards/list'),
      }),
    },
    {
      path: '/cb-standards/technical-sectors',
      component: Loadable({
        loader: () => import('../views/cb-standards/technical-sectors'),
      }),
    },
    {
      exact: true,
      path: '/cb-standards/key-locations',
      component: Loadable({
        loader: () => import('../views/cb-standards/key-locations'),
      }),
    },
    {
      exact: true,
      path: '/cb-standards/country',
      component: Loadable({
        loader: () => import('../views/cb-standards/country'),
      }),
    },
    {
      path: '/cb-standards/:standardId',
      component: Loadable({
        loader: () => import('../views/cb-standards/view'),
      }),
      routes: [
        {
          exact: true,
          path: '/cb-standards/:standardId',
          component: Loadable({
            loader: () => import('../views/cb-standards/view/details'),
          }),
        },
        {
          exact: true,
          path: '/cb-standards/:standardId/technical-sectors',
          component: Loadable({
            loader: () =>
              import('../views/cb-standards/view/technical-sectors'),
          }),
        },
        {
          exact: true,
          path: '/cb-standards/:standardId/country',
          component: Loadable({
            loader: () => import('../views/cb-standards/view/country'),
          }),
        },
      ],
    },
  ],
};

export default cbStandards;

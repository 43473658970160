import Loadable from 'components/Loadable';
import {ENABLE_CB_CREATE} from 'configs/enable-features';

const cbV2Routes = [
  {
    exact: true,
    path: '/certification-bodies/v2',
    component: Loadable({
      loader: () => import('../views/certification-bodies/v2/list'),
    }),
  },
  {
    exact: true,
    path: '/certification-bodies/v2/create',
    component:
      ENABLE_CB_CREATE &&
      Loadable({
        loader: () => import('../views/certification-bodies/v2/create'),
      }),
  },
  {
    path: '/certification-bodies/v2/:cbId',
    component: Loadable({
      loader: () => import('../views/certification-bodies/v2/view'),
    }),
    routes: [
      {
        exact: true,
        path: '/certification-bodies/v2/:cbId',
        component: Loadable({
          loader: () => import('../views/certification-bodies/v2/view/details'),
        }),
      },
      {
        exact: true,
        path: '/certification-bodies/v2/:cbId/technical-sectors',
        component: Loadable({
          loader: () =>
            import('../views/certification-bodies/v2/view/technical-sectors'),
        }),
      },
      {
        exact: true,
        path: '/certification-bodies/v2/:cbId/country',
        component: Loadable({
          loader: () => import('../views/certification-bodies/v2/view/country'),
        }),
      },
      {
        exact: true,
        path: '/certification-bodies/v2/:cbId/key-locations',
        component: Loadable({
          loader: () =>
            import('../views/certification-bodies/v2/view/key-locations'),
        }),
      },

      {
        path: '/certification-bodies/v2/:cbId/cb-standards',
        component: Loadable({
          loader: () =>
            import('views/certification-bodies/v2/view/cb-standards'),
        }),
        routes: [
          {
            exact: true,
            path: '/certification-bodies/v2/:cbId/cb-standards',
            component: Loadable({
              loader: () =>
                import('views/certification-bodies/v2/view/cb-standards/list'),
            }),
          },
          {
            exact: true,
            path: '/certification-bodies/v2/:cbId/cb-standards/assign',
            component: Loadable({
              loader: () =>
                import(
                  'views/certification-bodies/v2/view/cb-standards/assign'
                ),
            }),
          },
          {
            path: '/certification-bodies/v2/:cbId/cb-standards/:standardId',
            component: Loadable({
              loader: () =>
                import(
                  '../views/certification-bodies/v2/view/cb-standards/view'
                ),
            }),
            routes: [
              {
                exact: true,
                path: '/certification-bodies/v2/:cbId/cb-standards/:standardId',
                component: Loadable({
                  loader: () =>
                    import(
                      '../views/certification-bodies/v2/view/cb-standards/view/details'
                    ),
                }),
              },
              {
                path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/technical-sectors',
                component: Loadable({
                  loader: () =>
                    import(
                      'views/certification-bodies/v2/view/cb-standards/view/technical-sectors'
                    ),
                }),
                routes: [
                  {
                    exact: true,
                    path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/technical-sectors',
                    component: Loadable({
                      loader: () =>
                        import(
                          'views/certification-bodies/v2/view/cb-standards/view/technical-sectors/list'
                        ),
                    }),
                  },
                  {
                    exact: true,
                    path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/technical-sectors/assign',
                    component: Loadable({
                      loader: () =>
                        import(
                          '../views/certification-bodies/v2/view/cb-standards/view/technical-sectors/assign'
                        ),
                    }),
                  },
                ],
              },
              {
                path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/country',
                component: Loadable({
                  loader: () =>
                    import(
                      'views/certification-bodies/v2/view/cb-standards/view/country'
                    ),
                }),
                routes: [
                  {
                    exact: true,
                    path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/country',
                    component: Loadable({
                      loader: () =>
                        import(
                          'views/certification-bodies/v2/view/cb-standards/view/country/list'
                        ),
                    }),
                  },
                  {
                    exact: true,
                    path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/country/assign',
                    component: Loadable({
                      loader: () =>
                        import(
                          '../views/certification-bodies/v2/view/cb-standards/view/country/assign'
                        ),
                    }),
                  },
                ],
              },
              {
                path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/key-locations',
                component: Loadable({
                  loader: () =>
                    import(
                      'views/certification-bodies/v2/view/cb-standards/view/key-locations'
                    ),
                }),
                routes: [
                  {
                    exact: true,
                    path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/key-locations',
                    component: Loadable({
                      loader: () =>
                        import(
                          'views/certification-bodies/v2/view/cb-standards/view/key-locations/list'
                        ),
                    }),
                  },
                  {
                    exact: true,
                    path: '/certification-bodies/v2/:cbId/cb-standards/:standardId/key-locations/assign',
                    component: Loadable({
                      loader: () =>
                        import(
                          '../views/certification-bodies/v2/view/cb-standards/view/key-locations/assign'
                        ),
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default cbV2Routes;

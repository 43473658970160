import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {Link, Redirect} from 'react-router-dom';

import {Button, Row, Col} from 'reactstrap';
import {PublicLayout} from 'components/Layouts';
import {
  ERROR_STATUS,
  ERROR_STATUS_SUB_HEADER,
  ERROR_STATUS_HEADER,
} from 'configs';
import {Header} from 'components/Layouts/LocalLayout';
import {startCase} from 'lodash';

import {isEmpty} from 'lodash';
import {IAF_MAIN_URL} from 'configs/Environment';

function Errors(props) {
  const {errCode, onTryAgain} = props;

  if (isEmpty(errCode)) return <Redirect to="/not-found" />;

  return (
    <PublicLayout header={<Header />}>
      <div
        id={`status-code-${errCode}`}
        className={classNames(styles['error-page'], `status-code-${errCode}`)}
      >
        <p className={classNames(styles['error-msg'])}>
          <i
            className={classNames(
              styles['error-msg__icon'],
              'fa fa-exclamation-triangle fa-lg'
            )}
          />{' '}
          {errCode
            ? ERROR_STATUS_SUB_HEADER[errCode]
            : ERROR_STATUS_SUB_HEADER['404']}
        </p>

        <h1 className="mb-4">{startCase(ERROR_STATUS_HEADER[errCode])}</h1>

        {errCode !== ERROR_STATUS['google_translate'] && (
          <p className={classNames(styles['error-msg'])}>
            Sorry...We can't seem to find the page you are looking for.
          </p>
        )}

        {errCode !== ERROR_STATUS['google_translate'] && (
          <Button
            className={styles['error-btn']}
            tag={Link}
            outline
            color="light"
            size="sm"
            to="/"
          >
            Back to Homepage
          </Button>
        )}

        {errCode === ERROR_STATUS['google_translate'] && !!onTryAgain && (
          <Button outline color="light" size="sm" onClick={() => onTryAgain()}>
            Reload the page
          </Button>
        )}

        {errCode === '401' && (
          <Row className="text-light mt-5 justify-content-center align-items-center">
            <Col sm="auto">Do you have an account?</Col>
            <Col sm="auto">
              <Button
                tag="a"
                color="link"
                className="text-light px-0"
                href={`${IAF_MAIN_URL}/login`}
                style={{textDecoration: 'underline'}}
              >
                Log in
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </PublicLayout>
  );
}

export default Errors;

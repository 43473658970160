import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {IS_STAGE_DEV, ROUTE_ENV_RULES} from 'configs';
import {AuthConsumer} from 'contexts/AuthContext';
import {IAF_MAIN_URL} from 'configs/Environment';

const PrivateRoute = ({
  env,
  isAuth,
  isConsented,
  isEmailConfirmed,
  path,
  routes,
  component: Component,
  cbTemplate,
  certTemplate,
  ...rest
}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;

  return (
    <Route
      {...rest}
      render={(props) => {
        // 1.  Check Authentication

        const isNotPageException = [
          '/setup',
          '/consents',
          '/logout',
          '/e-signature',
        ].every((p) => props.match.url !== p);

        // 2.  Check Consents
        if (!isConsented && isNotPageException) {
          return <Redirect to="/consents" />;
        }

        // 3. Check Email Confirmation
        if (!isEmailConfirmed && isNotPageException) {
          return <Redirect to="/setup" />;
        }

        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return (
          <Component
            {...props}
            routes={routes}
            cbTemplate={cbTemplate}
            certTemplate={certTemplate}
          />
        );
      }}
    />
  );
};

const renderRoutes = (routes) => {
  // A <Switch> renders the first child <Route> that matches. A <Route> with no path always matches.
  return (
    <AuthConsumer>
      {({
        account,
        isAuth,
        isConsented,
        isMainAccount,
        cbTemplate,
        certTemplate,
      }) => {
        if (!isAuth || isMainAccount) {
          window.location.replace(IAF_MAIN_URL);
          return;
        }

        return (
          // Filter routes by access rules
          <Switch>
            {routes.map(({env, access, ...rest}, i) => {
              // Authenticated User Only
              return (
                <PrivateRoute
                  key={i}
                  env={env}
                  isEmailConfirmed={
                    isAuth && account.account_confirm_email === 1
                  }
                  isAuth={isAuth}
                  isConsented={isConsented}
                  cbTemplate={cbTemplate}
                  certTemplate={certTemplate}
                  {...rest}
                />
              );
            })}

            <Route render={() => <Redirect to="/not-found" />} />
          </Switch>
        );
      }}
    </AuthConsumer>
  );
};

export default renderRoutes;

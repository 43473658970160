import {isEmpty, isString, truncate} from 'lodash';
import {alertBase} from 'utils/alerts';

export const selectText = (elementId) => {
  if (document.selection) {
    // IE
    let range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(elementId));
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
};

export const copyToClipboard = (node, message) => {
  node.select(); // Select the text field
  document.execCommand('Copy'); // Copy the text inside the text field

  return alertBase({
    type: 'success',
    text: message ? message : 'The code has been copied to the clipboard.',
  });
};

export function exportCSVFile(fileName, csv) {
  const exportedFileName = `${fileName}.csv` || 'export.csv';

  let blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
    type: 'text/csv;charset=utf-8;',
  });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const getFileExtension = (fileName, addDot = false) => {
  const a = fileName.split('.');
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return '';
  }

  if (addDot) {
    return `.${a.pop()}`;
  } else {
    return a.pop(); // feel free to tack .toLowerCase() here if you want
  }
};

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const joinCommas = (array, lastSeparator = 'and') =>
  !isEmpty(array) &&
  array.reduce(
    (a, b, i, array) =>
      a + (i < array.length - 1 ? ', ' : ` ${lastSeparator} `) + b
  );

export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = ''
) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key;
      const value = obj[key];

      if (value instanceof File || value instanceof Blob) {
        // If the value is a File or Blob, append it directly to FormData
        formData.append(propName, value);
      } else if (typeof value === 'object' && value !== null) {
        // If the value is an object, recursively call the function
        objectToFormData(value, formData, propName);
      } else if (typeof value === 'string' && value.startsWith('data:')) {
        // If the value is a base64 string, convert it to a Blob and append
        const [dataType, base64Data] = value.split(',');
        const contentType = dataType.match(/:(.*?);/)[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        formData.append(propName, blob, key);
      } else {
        // For other types, simply append to FormData
        formData.append(propName, value);
      }
    }
  }

  return formData;
}

// Utility function to format data source labels
export const formatDataSourceLabel = (acronym, name) => {
  let label = '';

  if (acronym) {
    return `${truncate(acronym, {length: 12})} Data`;
  }

  label =
    isString(name) &&
    `${name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 5)} Data`;

  return label;
};
